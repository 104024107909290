import React,{useState} from "react";
import Navbar from "components/Navbar/navbar";
import ParticalsOverlay from "components/Particals-Overlay/ParticalsOverlay";
// import WorkCulture from "components/work-culture";
import DarkTheme from "layouts/Dark";
import Footer from "components/Footer/footer";
import Oppturtunities from "components/Oppturtunities/oppturtunities";
import SEO from "components/seo/seo";
import LoadingScreen from "components/Loading-Screen";

const Career = () => {
    const navbarRef = React.useRef(null);
    const logoRef = React.useRef(null);
    const fixedHeader = React.useRef(null);
    const MainContent = React.useRef(null);
  
    const [theme, setTheme] = useState("Dark");
  
    React.useEffect(() => {
      var navbar = navbarRef.current;
  
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > 300) {
          navbar.classList.add("nav-scroll");
        } else {
          navbar.classList.remove("nav-scroll");
        }
      });
  
      var storage = localStorage.getItem("theme");
  
      if (storage && storage != "") {
        let bodyEl = document.querySelector("body");
        bodyEl.classList.add("themeL");
        setTheme(storage);
      }
  
      setTimeout(() => {
        if (fixedHeader.current) {
          var slidHeight = fixedHeader.current.offsetHeight;
        }
        if (MainContent.current) {
          MainContent.current.style.marginTop = slidHeight + "px";
        }
      }, 1000);
    }, [fixedHeader, navbarRef]);
  
    const themeModeChange = (themeMode) => {
      let bodyEl = document.querySelector("body");
      if (themeMode == "Dark") {
        setTheme("themeL");
        bodyEl.classList.add("themeL");
        localStorage.setItem("theme", "themeL");
      } else {
        setTheme("Dark");
        bodyEl.classList.remove("themeL");
        localStorage.removeItem("theme");
      }
    };

  return (
    <div>
      <DarkTheme>
        <LoadingScreen/>
      <Navbar
        nr={navbarRef}
        lr={logoRef}
        from="about-dark"
        theme={theme}
        themeModeChange={themeModeChange}
      />
      <header
        ref={fixedHeader}
        className="works-header fixed-slider hfixd valign sub-bg"
      >
        <ParticalsOverlay />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-9 static">
              <div className="capt mt-50">
                <div className="parlx text-center">
                  <h1 className="color-font">Career</h1>
                </div>

                <div className="bactxt custom-font valign">
                  <span className="full-width banner-bg-large-text">
                    Career
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div ref={MainContent} className="main-content">
        <Oppturtunities/>
        {/* <Gallery/>
        <Lightbox/> */}
      <Footer/>
      </div>
      </DarkTheme>
      <SEO/>
    </div>
  );
};

export default Career;
